export const PREVIEW_PRORATION = gql`
  query previewProration($stripeProductPriceId: String!) {
    previewProration(stripeProductPriceId: $stripeProductPriceId)
  }
`

export const UPGRADE_SUBSCRIPTION = gql`
  mutation UpgradeSubscription($stripePriceId: String!) {
    upgradeSubscriptionTier(stripePriceId: $stripePriceId)
  }
`

export const UPDATE_SUBSCRIPTION_ADDONS = gql`
  mutation UpdateSubscriptionAddons($stripePriceId: String!, $unitAmount: Int) {
    updateSubscriptionAddons(
      stripePriceId: $stripePriceId
      unitAmount: $unitAmount
    )
  }
`
