import { useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import UpgradeAddonCell from 'src/components/Billing/UpgradeAddonCell'
import UpgradeSubscriptionCell from 'src/components/Billing/UpgradeSubscriptionCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const ClientSubscriptionUpgradePage = ({ addonId }) => {
  const location = useLocation()
  const { pathname } = location
  const shouldShowAddon = pathname.includes('addon')
  return (
    <>
      <Metadata
        title="Upgrade Subscription Plan"
        description="Upgrade a hubs subscription plan"
      />
      <PageHeader
        title="Upgrade Subscription Plan"
        backNavigation
        parentDataTestId="upgrade-client-subscription-page"
      />
      {addonId || shouldShowAddon ? (
        <div className={'w-full'}>
          <UpgradeAddonCell addonId={addonId} />
        </div>
      ) : (
        <div className={'w-full'}>
          <UpgradeSubscriptionCell />
        </div>
      )}
    </>
  )
}

export default ClientSubscriptionUpgradePage
