import React from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { BILLING_CYCLE } from 'api/src/common/enums'
import { isNotNil } from 'ramda'
import { ProductPlan } from 'types/graphql'

import { CustomAddon } from 'src/components/Billing/ProductPlansCell/ProductPlansCell'

interface AddonProps {
  addon: CustomAddon | ProductPlan
  isSelected: boolean
  onClick: (passedAddon: CustomAddon | ProductPlan) => void
  billingPeriod: string
  disabled?: boolean
  currentCustomerAddon?: {
    id: string
    quantity: number
  }
  newSale?: boolean
}

const Addon = ({
  addon,
  isSelected,
  onClick,
  billingPeriod,
  disabled = false,
  currentCustomerAddon,
  newSale = false,
}: AddonProps) => {
  const addonPrice =
    billingPeriod === BILLING_CYCLE.MONTHLY
      ? addon.defaultPrice
      : addon.yearlyPrice
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: addonPrice?.currency || 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const tiers =
    billingPeriod === BILLING_CYCLE.MONTHLY
      ? addon.defaultPrice.tiers?.slice(0, addon.defaultPrice.tiers.length - 1)
      : addon.yearlyPrice?.tiers?.slice(0, addon.yearlyPrice?.tiers?.length - 1)

  // check if the addon has tiers if so do some recursive returns
  if (isNotNil(tiers)) {
    //   check which tier the customer is on
    return (
      <>
        {tiers.map((tier, index) => {
          const cost =
            index === 0
              ? tier.flatAmountDecimal
              : tiers
                  .slice(0, index + 1)
                  .reduce(
                    (partialSum, x) => partialSum + x.flatAmountDecimal,
                    0,
                  )

          const tierAddon = {
            ...addon,
            name: `${addon.name} - Tier ${index + 1}`,
            description: `${addon.description} - up to ${tier.upTo}`,
            defaultPrice: {
              ...addon.defaultPrice,
              unitAmount: cost,
              tiers: null,
            },
            upTo: tier.upTo,
            yearlyPrice: {
              ...addon.yearlyPrice,
              unitAmount: cost,
              tiers: null,
            },
          }
          const tierDisabled =
            tierAddon.upTo < currentCustomerAddon?.quantity && !newSale
          return (
            <div className={'my-4'} key={tier.upTo}>
              <Addon
                disabled={tierDisabled}
                addon={tierAddon as CustomAddon}
                onClick={() => {
                  if (currentCustomerAddon?.quantity === tier.upTo && !newSale)
                    return
                  onClick(tierAddon as CustomAddon)
                }}
                billingPeriod={billingPeriod}
                isSelected={currentCustomerAddon?.quantity === tier?.upTo}
              />
            </div>
          )
        })}
      </>
    )
  }

  // default return
  return (
    <Card
      className={`${
        isSelected
          ? 'ring-2 ring-indigo-600 bg-indigo-50'
          : 'ring-1 ring-gray-400'
      } rounded-xl max-w-3xl mx-auto transition-all ${
        !disabled
          ? 'cursor-pointer hover:bg-indigo-100 hover:ring-2 hover:ring-indigo-500'
          : 'cursor-not-allowed bg-gray-100 ring-gray-400'
      }`}
      onClick={() => {
        if (disabled) return
        onClick(addon)
      }}
    >
      <CardHeader
        title={
          <h3
            className={` ${
              isSelected ? 'text-indigo-600' : 'text-gray-900'
            } text-lg font-medium leading-8`}
          >
            {addon.name}
          </h3>
        }
        subheader={
          <p className="text-sm font-light leading-5 text-gray-500 break-words">
            {addon.description}
          </p>
        }
        action={
          <Stack
            spacing={2}
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            className={'ml-8 mr-4 mt-1 text-align-center'}
          >
            <span className="text-5xl font-w font-normal tracking-tight text-gray-900">
              {formatter.format(Math.round(addonPrice?.unitAmount / 100))}
            </span>
            <div className="text-md leading-4 text-gray-600 ml-4">
              Billed {billingPeriod} <br />
              <p className={'text-xs'}>excludes GST</p>
            </div>
          </Stack>
        }
      />
    </Card>
  )
}

export default Addon
